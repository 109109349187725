/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "When I was in college, AI and machine learning still seemed like novel concepts exclusive to researchers. However, in November 2022, right after my graduation, OpenAI introduced ChatGPT to the world, and AI suddenly became a commercialized product that could be used by the general public on a daily basis.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "More and more people began discussing the possibilities that AI technology could bring to human society. Today, I want to talk about my vision of how AI will impact future work patterns and the trends it will bring to the workforce market.")), "\n", React.createElement("h3", null, React.createElement(_components.p, null, "💡 There will be unemployment, and this should be exciting.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "I currently work as a quality assurance engineer in the software industry. Every day, I test various software products and try to make sure new features work as expected and the code changes haven't broken existing functionality.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "Certainly, the quality of delivery has an inseparable relationship with the success of software products. But when I reflect on myself: does this QA job truly utilize my own potential and creativity as a living human?")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "My answer is no. If AI can learn from source code and service architecture to directly generate potential functional, performance, and security vulnerability reports for programmers, and tell product managers which affected user flows need acceptance testing, then I as a QA engineer can retire worry-free.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "At that time, I will either be forced or voluntarily leave my current position to pursue other work that may not be even imagineable today.")), "\n", React.createElement("h3", null, React.createElement(_components.p, null, "💡 No more specialized roles, only super workers will survive.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "Taking my current software industry as an example, there are many roles in the product development lifecycle(SDLC): product managers, UI/UX designers, frontend developers, backend developers, data analysts, QA engineers, operations, and so on. For the past 20+ years, Agile, Kanban, and other models similar to Toyota's factory management from the last century have been widely adopted in the industry.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "This means everyone only does one job on the whole pipeline - for example, as a QA engineer, I don't participate in writing and debugging software code.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "I used to believe this was the optimal work pattern in the software industry, until the wave of AI adoption arrived. The concept of \"super workers\" caught my attention. With AI's help, I can skip lengthy and boring basic programming courses, and quickly dive into understanding how the frameworks are designed and how I should implement my ideas as code.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "With AI's help, I don't need to know every detail of CI/CD pipelines to add in a stage for my automated test suites. Gradually, the walls between specialized positions in the SDLC are silently collapsing.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "Imagine what work would be like if a single person can independently complete a software feature, from a requirement document to the final deployment? We wouldn't have to worry about losing context during task handovers, nor waste time waiting for engineers from previous stages to return from PTO.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "Peer Programming and Test-driven development would no longer be vague concepts. Two or more super workers can review each other's work at planned checkpoints or milestones, to prevent product failures due to individual workers' cognitive and execution biases.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "Leaders and managers should act as cheerleaders, focusing on aligning individual super workers' work with corporate mission and vision, meanwhile give superworkers sufficient care, coaching, and freedom in working hours and location.")), "\n", React.createElement("h3", null, React.createElement(_components.p, null, "💡 Customer success engineer is everyone's ultimate job title.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "I often encourage my friends from other industries to learn programming or software technology, because there are plenty of free learning materials and courses accessible on the Internet. Our lives have been thoroughly changed by electronic products and applications installed on the screens.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "In a few years, programming skills should be mastered by almost all humans just like how to type with keyboard.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "Rather than being a self-pitying laid-off typist or QA engineer, why not start paying attention to your daily conversations with people around you? Amplify your senses, identify small opportunities in life - you might be a potential customer for your own product.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "How much value you can create for customers determines how much money you can exchange back from other humans in the society. Money isn't scarce or valuable, but it can exchange for better experiences in life.")), "\n", React.createElement("p", {
    className: "pt-1"
  }, React.createElement(_components.p, null, "I wish everyone can become super workers with happiness and freedom in the AI era.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
